@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.action-container {
  display: flex;
  margin-bottom: 20px;
}

.form-box {
  max-width: 70%;
  padding: var(--box-padding-admin);
  background-color: var(--color-element-layer1);
  box-shadow: var(--box-shadow-small-elevation);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border-theme);

  h2 {
    margin-bottom: 20px;
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.parentalLine {
  color: var(--color-text-subtle-theme);
}
